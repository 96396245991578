import React from 'react';
import { Block, Flex, Heading, Offscreen } from '../../../components/styled';
import LinkedinIcon from '../assets/linkedin.svg';
import {
  getAsset,
  IconLink,
  Leader,
  LeaderInfoPos,
  LeaderInfoTitle,
} from '../leadership-common';

const FidelLeadership: React.FC = () => {
  const leadership: Leader[] = [
    {
      name: 'Carolina Paradas',
      pos: 'VP, Growth',
      linkedin: 'carolina-p-693b418a',
      photo: 'Carrie',
    },
    {
      name: 'Kevin Akerman',
      pos: 'VP, Product & Operations',
      linkedin: 'kevinakerman',
      photo: 'Kevin',
    },
  ];

  return (
    <Flex flow="column" breakFlow="column">
      <Block margin="1rem 0" width="100%">
        <Heading weight={300} lineHeight="1.47" style={{ marginBottom: 0 }}>
          Fidel Leadership
        </Heading>
      </Block>
      <Flex gap="3rem" justifyContent="normal">
        {leadership.map(({ name, photo, pos, linkedin }: Leader) => (
          <Block width="18%">
            <img
              src={getAsset(`Fidel_leadership_${photo}`).src}
              srcSet={getAsset(`Fidel_leadership_${photo}`).srcSet}
              style={{ borderRadius: '1rem' }}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <LeaderInfoTitle>{name}</LeaderInfoTitle>
              <LeaderInfoPos>{pos}</LeaderInfoPos>
              <IconLink
                href={`https://www.linkedin.com/in/${linkedin}/`}
                className="link-primary"
              >
                <Offscreen>{`View ${name}’s LinkedIn profile`}</Offscreen>
                <LinkedinIcon />
              </IconLink>
            </div>
          </Block>
        ))}
      </Flex>
    </Flex>
  );
};

export default FidelLeadership;
