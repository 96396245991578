import React from 'react';

import { Block, Flex, Heading, Offscreen } from '../../../components/styled';
import LinkedinIcon from '../assets/linkedin.svg';
import {
  Leader,
  LeaderInfoTitle,
  LeaderInfoPos,
  getAsset,
  IconLink,
} from '../leadership-common';

const Leadership: React.FC = () => {
  const leadership: Leader[] = [
    {
      name: 'Bishara Smeir',
      pos: 'CEO',
      linkedin: 'theopportunity',
      photo: 'Bish',
    },
    {
      name: 'Shawn Frazer',
      pos: 'CFO',
      linkedin: 'shawn-frazer-85ab1216',
      photo: 'Shawn',
    },
    {
      name: 'Barry Helfrich',
      pos: 'CIO',
      linkedin: 'barry',
      photo: 'Barry',
    },
    {
      name: 'Arron Edwards',
      pos: 'CTO',
      linkedin: 'arronedwards',
      photo: 'Arron',
    },
  ];

  return (
    <Flex flow="column" breakFlow="column">
      <Block margin="1rem 0" width="100%">
        <Heading weight={300} lineHeight="1.47" style={{ marginBottom: 0 }}>
          Enigmatic Smile Leadership
        </Heading>
      </Block>
      <Flex gap="3rem" justifyContent="normal">
        {leadership.map(({ name, photo, pos, linkedin }: Leader) => (
          <Block width="18%">
            <img
              src={getAsset(`Fidel_ES_leadership_${photo}`).src}
              srcSet={getAsset(`Fidel_ES_leadership_${photo}`).srcSet}
              style={{ borderRadius: '1rem' }}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <LeaderInfoTitle>{name}</LeaderInfoTitle>
              <LeaderInfoPos>{pos}</LeaderInfoPos>
              <IconLink
                href={`https://www.linkedin.com/in/${linkedin}/`}
                className="link-primary"
              >
                <Offscreen>{`View ${name}’s LinkedIn profile`}</Offscreen>
                <LinkedinIcon />
              </IconLink>
            </div>
          </Block>
        ))}
      </Flex>
    </Flex>
  );
};

export default Leadership;
