import { breakpoints } from '@src/components/styled/variables';
import styled from 'styled-components';

export interface Leader {
  name: string;
  pos: string;
  linkedin: string;
  photo: string;
}

export const LeaderInfoTitle = styled.h3`
  margin: 0;
  margin-top: 0.5rem;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    height: auto;
    margin-top: 1rem;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: auto;
    margin-top: 1rem;
  }
`;

export const LeaderInfoPos = styled.h5`
  margin: 0;

  @media (max-width: ${breakpoints.tabletPortrait}) {
    height: auto;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: auto;
  }
`;

export const IconLink = styled.a`
  border-bottom: none;

  &::after {
    display: none;
  }
`;

export function getAsset(name: string) {
  const src = require(`./assets/${name}.png`).default;
  const srcSet = `${src}`;

  return { src, srcSet };
}
